<template>
    <div class="settle">
        <el-card>
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>结算明细</span>
                </div>
            </template>
            <div class="content-header">
                <el-row :gutter="5">
                    <el-col :span="24" style="text-align:left;">
                        <div class="searchblock">
                            <el-select v-model="merchantid" filterable style="margin-right:10px;width:150px" clearable
                                placeholder="请选择商户" size="small" >
                                <el-option v-for="item in merchants" :key="item" :value="item.ID"
                                    :label="item.Merchant"></el-option>
                            </el-select>
                            <el-select v-model="type" style="margin-right:10px;width:150px" clearable size="small">
                                <el-option label="租金结算" value='1'></el-option>
                                <el-option label="租金结算(补)" value='2'></el-option>
                                <el-option label="商户提现" value='3'></el-option>
                            </el-select>
                            <el-date-picker v-model="range" type="date" style="margin-right:10px;" size="small"
                                placeholder="请选择日期"></el-date-picker>
                            <el-input v-model="keyword" placeholder="请输入流水号/订单号" prefix-icon="el-icon-search"
                                class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                            <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down"
                                size="small">搜索</el-button>
                            <el-button type="success" @click="add" style="margin-left:10px;" class="hidden-sm-and-down"
                                size="small" v-if="ismerchant != 1">新增结算</el-button>
                            <el-button type="primary" size="small">
                                <download-excel class="btn btn-default" :fetch="fetchData" :fields="json_fields"
                                    :before-generate="startDownload" :before-finish="finishDownload"
                                    worksheet="My Worksheet" type="csv" name="结算数据.csv">
                                    <span style="padding: 11px 20px;margin: 11px -20px;">导出</span>
                                </download-excel>
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div style="padding:20px 20px 0 0;width:30%;display:flex;justify-content:space-between;">
                <span>结算总金额:{{ total ? total : 0 }}元</span>
            </div>
            <el-table :data="tabledata" border size="small" style="margin:20px 0;">
                <el-table-column type="index" label="序号" align="center"></el-table-column>
                <el-table-column label="商户名称" prop="Merchant"></el-table-column>
                <el-table-column label="账户类型" prop="Type">
                    <template #default='scope'>
                        <span v-if="scope.row.Type == 1">租金结算</span>
                        <span v-if="scope.row.Type == 2">租金结算(补)</span>
                        <span v-if="scope.row.Type == 3">提现</span>
                        <span v-if="scope.row.Type == 5">买断金结算</span>
                        <span v-if="scope.row.Type == 7">审核费</span>
                        <span v-if="scope.row.Type == 6">锁机费</span>
                    </template>
                </el-table-column>
                <el-table-column label="结算金额" prop="Amount">
                    <template #default='scope'>
                        <span v-if="scope.row.Amount >= 0">
                            <span style="color:red;" v-if="scope.row.Type == 7 || scope.row.Type == 6">
                                -¥{{ scope.row.Amount }}
                            </span>
                            <span v-else>¥{{ scope.row.Amount }}</span>
                        </span>
                        <span v-if="scope.row.Amount < 0" style="color:#ff0000">
                            <span style="color:red;" v-if="scope.row.Type == 7 || scope.row.Type == 6">
                                +¥{{ scope.row.Amount * -1 }}
                            </span>
                            <span v-else>¥{{ scope.row.Amount }}</span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="账户余额" prop="CurCredit">
                    <template #default='scope'>
                        ¥{{ scope.row.CurCredit }}
                    </template>
                </el-table-column>
                <el-table-column label="交易流水" prop="TradeNo"></el-table-column>
                <el-table-column label="订单编号" prop="OrderUID"></el-table-column>
                <el-table-column label="收货人姓名" prop="Receiver"></el-table-column>
                <el-table-column label="期数" prop="PeriodNo"></el-table-column>
                <el-table-column label="结算比例" prop="Rate"></el-table-column>
                <el-table-column label="结算时间" prop="AddDTime">
                    <template #default='scope'>
                        {{ formatDate(scope.row.AddDTime) }}
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="Remark"></el-table-column>
                <el-table-column label="核验" prop="checkmoney">

                </el-table-column>
            </el-table>
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="curpage"
                :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
                layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page="true"
                style="text-align:center"></el-pagination>
        </el-card>
    </div>
    <el-dialog v-model="addsettle" title="新增结算记录" width="37%" center>

        <el-form :model="settleinfo" ref="settleinfo" label-width="150px" :rules="rules">
            <el-form-item class="tag-form-item">
                <el-tag size="small">提示：结算金额可以为负数</el-tag>
            </el-form-item>
            <el-form-item label="商户名称:" prop="merchantid">
                <el-select v-model="settleinfo.merchantid" filterable placeholder="请选择商户" style="width:250px;">
                    <el-option v-for="item in merchants" :key="item" :label="item.Merchant"
                        :value="item.ID"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="结算金额:" prop="amount">
                <el-input-number v-model="settleinfo.amount" :controls='false'></el-input-number>
            </el-form-item>
            <el-form-item label="结算类型:" prop="type">
                <el-select v-model="settleinfo.type" placeholder="请选择类型">
                    <el-option label="租金结算" value="2" />
                    <el-option label="锁机费" value="6" />
                    <el-option label="审核费" value="7" />
                </el-select>
            </el-form-item>
            <el-form-item label="关联订单号:" prop="orderuid">
                <el-input v-model="settleinfo.orderuid" style="width:250px;"></el-input>
            </el-form-item>
            <el-form-item label="备注:" prop="remark">
                <el-input type="textarea" :rows="4" style="width:250px;" v-model="settleinfo.remark"></el-input>
            </el-form-item>

        </el-form>
        <div class="info">
            <div style="display:flex;flex-direction: column;justify-content: center;align-items: flex-start;">
                <strong style="color:#ff0000">提示：新增结算，涉及到商户金额，请务必谨慎操作！</strong>
            </div>
        </div>
        <template #footer>
            <el-button size="small" @click="Save" type="primary">确定</el-button>
            <el-button size="small" @click="Cancel">取消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import qs from 'qs'
import Cookies from 'js-cookie'
import { ElMessage } from "element-plus";
import JsonExcel from "vue-json-excel3";
import constant from '@/constant'
export default {
    components: {
        downloadExcel: JsonExcel,
    },
    data() {
        return {
            merchants:[],
            tabledata: [],
            ismerchant: '',
            mid: '',
            addsettle: false,
            keyword: '',
            range: '',
            merchantid: '',
            curpage: 1,
            pagesize: 10,
            totalcount: '',
            type: '',
            settleinfo: {
                merchantid: '',
                amount: '',
                type: '',
                orderuid: '',
                remark: '',
                checkmoney: ''
            },
            rules: {
                merchantid: [{
                    required: true,
                    message: '请选择商户',
                    trigger: 'blur'
                }],
                amount: [{
                    required: true,
                    message: '请填写金额',
                    trigger: 'blur'
                }],
                orderuid: [{
                    required: true,
                    message: '请填写订单号',
                    trigger: 'blur'
                }],
                remark: [{
                    required: true,
                    message: '请填写备注',
                    trigger: 'blur'
                }]
            },
            json_fields: {
                "商户名称": "Merchant",
                "账户类型": {
                    field: "Type", callback: (value) => {
                        if (value == 1) {
                            return `租金结算`;
                        }
                        if (value == 2) {
                            return `租金结算(补)`;
                        }
                        if (value == 3) {
                            return `提现`;
                        }
                        if (value == 4) {
                            return `续租`;
                        }
                        if (value == 7) {
                            return `审核费`;
                        }
                        if (value == 6) {
                            return `锁机费`;
                        }
                    }
                },
                "结算金额": "Amount",
                "账户余额": "CurCredit",
                "交易流水": "TradeNo",
                "订单编号": "OrderUID",
                "收货人姓名": "Receiver",
                "期数": "PeriodNo",
                "结算比例": "Rate",
                "结算时间": "AddDTime",
                "备注": "Remark",
            },



        }
    },
    methods: {
        async fetchData() {
            const response = await this.axios.get(constant.export2, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    keyword: this.keyword,
                    merchantid: this.merchantid,
                    type: this.type,
                    range: this.range,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    ismerchant: this.ismerchant,
                    mid: this.mid,
                },
            })
            console.log(response.data.exportdata)
            if (response.data.exportdata.length == 0) {
                ElMessage({
                    type: "warning",
                    message: "暂无数据可下载",
                    center: true
                })
                return false;
            }
            return response.data.exportdata;
        },
        startDownload() {
            ElMessage({
                type: "warning",
                message: "开始下载",
                center: true,
            })
        },
        finishDownload() {
            ElMessage({
                type: "success",
                message: "下载完成",
                center: true,
            })
        },

        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        Save() {
            this.$refs['settleinfo'].validate((valid) => {
                if (valid) {
                    console.log(this.settleinfo);
                    this.axios.post(constant.savesettle, qs.stringify(this.settleinfo), {
                        headers: {
                            "content-type": "application/x-www-form-urlencoded"
                        }
                    }).then((response) => {
                        console.log(response.data);
                        if (response.data == "NOORDER") {
                            this.$message.error("订单号和商户不匹配");
                            return false;
                        } else if (response.data == "OK") {
                            this.$message.success("操作成功");
                            this.addsettle = false;
                            // this.$router.go(0);
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        Cancel() {
            this.addsettle = false;
        },
        add() {
            this.addsettle = true;
        },
        // export2(){
        //     this.axios.get(constant.export2,{
        //         headers:{
        //             "content-type":"application/json"
        //         },
        //         params:{
        //             keyword:this.keyword,
        //             merchantid:this.merchantid,
        //             type:this.type,
        //             range:this.range,
        //             curpage:this.curpage,
        //             pagesize:this.pagesize,
        //             ismerchant:this.ismerchant,
        //             mid:this.mid,
        //         }
        //     }).then((response)=>{
        //         console.log(response.data);
        //         this.tabledata = response.data.list;
        //         this.totalcount = response.data.totalcount*1;
        //         this.curpage = response.data.curpage*1;
        //         this.total = response.data.total*1;
        //     });
        // },
        init() {
            this.axios.get(constant.getsettle, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    keyword: this.keyword,
                    merchantid: this.merchantid,
                    type: this.type,
                    range: this.range,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    ismerchant: this.ismerchant,
                    mid: this.mid,
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.totalcount = response.data.totalcount * 1;
                this.curpage = response.data.curpage * 1;
                this.total = response.data.total * 1;
            });
        },
        formatDate(date) {
            var datetime = new Date(date * 1000);
            var year = datetime.getFullYear();
            var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
            var day = ("0" + datetime.getDate()).slice(-2);
            var hour = ("0" + datetime.getHours()).slice(-2);
            var minute = ("0" + datetime.getMinutes()).slice(-2);
            var second = ("0" + datetime.getSeconds()).slice(-2);
            return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
    },
    created: function () {
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.axios.get(constant.allmerchant, {
            headers: {
                "content-type": "application/json"
            }
        }).then((response) => {
            console.log(response.data);
            this.merchants = response.data;
        });
        this.init();
    }
}
</script>
<style>
.el-card {
    padding: 0 !important;
}

.el-card__body {
    padding: 20px;
}

.el-card__header {
    padding: 10px !important;
}

.el-form-item {
    margin-bottom: 15px;
}

.tag-form-item {
    margin-bottom: 0;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
